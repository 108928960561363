.fade-300-enter {
  opacity: 0;
}
.fade-300-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-300-exit {
  opacity: 1;
}
.fade-300-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}