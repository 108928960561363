.Accordeon{

}

.AccordeonContainer{
  overflow: hidden;
}

.Accordeon.cut .AccordeonContainer.closed{
  max-height: 400px;
}

.AccordeonContainer{
  position: relative;
}

.Accordeon.cut .AccordeonContainer.closed:after{
  display: block;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3rem;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}

.Accordeon.cut .AccordeonContainer.open {
  max-height: auto;
}

.AccordeonButton{
  margin: 1rem auto;
}