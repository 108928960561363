.Collaborator {
  margin-bottom: 4rem;
}

.Collaborator-block{
  margin-top: 2rem;
}

.CollaboratorView_Head{
  border-top: 1px solid $black;
  @include media-breakpoint-down(lg) {
    border-top: none;
  }
}

.CollaboratorView_CloseIcon {
}

.CollaboratorView_Link {
  margin-bottom: 1rem;
}