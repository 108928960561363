.Header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  user-select: none;
  display: flex;
  padding: .75rem;
}


.Header-NavToggler {
  display: none;
}


.Header-Logo {
  position: relative;
  z-index: 100;
  width: 100%;
  padding-right: .75rem;
  font-size: 1rem;
}


.Header-Navigation {
  width: 100%;
  position: relative;
  z-index: 100;
  height: 100%;
}


.Header-Navigation-Item {
  width: 100%;
  overflow: hidden;
  text-align: center;
  display: block;
}

.Header-Navigation-Item.active,
.Header-Navigation-Item:hover{
  background-color: $black;
  color: $white;
}
.Header-Navigation:hover .Header-Navigation-Item.active,
.Header-Navigation:hover .Header-Navigation-Item{
  color: $black;
  background-color: $white;
}
.Header-Navigation:hover .Header-Navigation-Item.active:hover,
.Header-Navigation:hover .Header-Navigation-Item:hover{
  color: $white;
  background-color: $black;
}

@include media-breakpoint-up(md) {

  // Header backgrond on desktop
  .Header:after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    transition: transform 100ms ease;
    background-color: $white;
    transform: translateY(0%);
    backdrop-filter: blur(5px);
  }
  .closed.Header:after {
    transform: translateY(-101%);
  }

  .Header-Navigation {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }

  .Header-Navigation-Item {
    transition: width 500ms ease;
    border-right: 3px solid $black;
  }
  .Header-Navigation-Item.first {
    border-left: 3px solid $black;
  }

  // hide Header on scroll (desktop)
  .Header.closed .Header-Navigation-Item {
    width: 0;
  }
  .Header.closed .Header-Navigation-Item-Inner {
    opacity: 0;
  }

  .Header-Navigation-Item-Inner {
    opacity: 1;
    transition: opacity 500ms linear;
  }

}





@include media-breakpoint-down(sm) {

  .Header {
    height: 2.5rem;
    overflow: visible;
    background-color: $white;
  }
  .Header.mobNavOpen {
    bottom: 0;
    height: auto;
  }

  .Header-Logo {
    z-index: 100;
    position: relative;
    height: 3rem;
    width: 50%;
  }

  .Header-NavToggler {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 3rem;
    z-index: 200;
    @include clickable-cursor;
    overflow: hidden;
  }

  .Header-NavToggler-Inner {
    margin-left: auto;
    position: relative;
    height: 3rem;
    width: 7rem;
    padding: .5rem;
    transform: translateY(0);
    transition: transform 300ms ease;

    svg {
      display: block;
      width: auto;
      height: 100%;
      margin-left: auto;
    }
  }

  .Header.mobNavClosed .Header-NavToggler-Inner {
    transform: translateY(-100%);
  }


  .Header-Navigation {
    position: absolute;
    right: 0;
    top: 3rem;
    z-index: 1;
    transform: translateY(0);
    transition: all 300ms ease;
    width: 100%;
    background-color: $white;
  }

  .Header-Navigation-Item {
    border-bottom: 1px solid $black;

    &.first {
      border-top: 1px solid $black;
    }
  }

  .Header-Navigation-Item {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: height 300ms ease;
  }

  .Header.mobNavClosed .Header-Navigation-Item {
    height: .5rem;

    .Header-Navigation-Item-Inner {
      opacity: 0;
    }
  }
  .Header.mobNavClosed .Header-Navigation-Item.active {
    height: 1rem;

    .Header-Navigation-Item-Inner {
      opacity: 1;
    }
  }


  .Header.mobNavClosed {
    .Header-Navigation {
      width: 7rem;
      top: 0;
      padding: 0;
    }

    .Header-NavToggler {
    }

    .Header-Navigation-Item {
      padding: 0;
    }

  }

}
