.area{
  border-left: 1px solid $black;
  margin-bottom: 3rem;
}

.area-head{
  background-color: $black;
  color: $white;
  padding: 2px;
  line-height: 1;
  font-size: 12px;
}
.area-head-lable{
  border-bottom: 1px solid $white;
}

.area-body{
  padding-left: 2px;
  padding-top: 2px;
}