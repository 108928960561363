.CalendarEntry{
  border-left: 1px solid $black;
  margin-bottom: 2rem;
}

.CalendarEntry-Head{
  padding: 1px 3px;
  display: flex;
  justify-content: space-between;
}

.CalendarEntry-Body{
  padding: 1px 3px;
}
.CalendarEntry-Body h3{
  margin-bottom: 0;
}

.CalendarEntry-Footer{
  display: flex;
  padding-left: 2px;
}
