.Contact-Member{
}

.Contact-Media{
  display: flex;
  a{
    display: block;
    margin-right: .5rem;
  }
  img{
    display: block;
    width: 2.5rem;
    height: 2.5rem;
  }
}

.Contact-Newsletter{
}