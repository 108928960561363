html, body, #root, .App {
  font-size: 13px;
  background-color: $white;
  -webkit-font-smoothing: antialiased;
  @include media-breakpoint-up(xl) {
    font-size: 15px;
  }
}

.App {
  position: relative;
}

html {
  overflow-y: scroll;
}

.container-fluid{
  // max-width: 1920px;
}

.button-reset{
  outline: none;
  background: none;
}

.button-svg{
  display: block;
  width: 2rem;
  height: 2rem;
  svg{
    display: block;
    width: 2rem;
    height: 2rem;
  }
}


button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  @include clickable-cursor;
}
