.Subheader {
  background-color: $white;
  z-index: 200;
  position: relative;
  user-select: none;
  ul{
    list-style-type: none;
    padding-left: 0;
    display: flex;
  }
  li{
    width: 25%;
    border-left: 1px solid  $black;
  }
  li.first{
    border-left: none;
  }

  a{
    display: block;
    border-top: 1px solid $black;
    text-align: center;
  }
  a.active{
    color: $white;
    background:  $black;
  }

  ul:hover a.active{
    color: $black;
    background:  $white;
  }

  ul:hover a:hover,
  ul:hover a.active:hover {
    background: $black;
    color:  $white;
  }



  @include media-breakpoint-down(sm){
    ul{
      display: block;
      border-bottom: 1px solid  $black;
    }
    li{
      width: 100%;
      border-left: none;
    }
    li.first{
    }
    a{

      padding: .5rem;
    }
  }



}