@keyframes ROTATION {
  0% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(100vw);
  }
}



.Loader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  top: -.25rem;
  display: flex;
  z-index: 1500;
}

.LoaderElement {
  position: absolute;
  top: 0;
  animation: ROTATION 3s linear;
  svg{
    height: 1rem;
    width: auto;
  }
}



.ProjectIndex-Loader {
  width: 100%;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Page-Loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}