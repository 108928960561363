.Image{
  transition: opacity 300ms linear;
  user-select: none;
}
.Image.loading{
  opacity: 0;
}
.Image.loaded{
  opacity: 1;
}


.ratio-wrapper{
  position: relative;
  width: 100%;
  background-color: #f0f0f0;
}
.ratio-wrapper-inner{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}