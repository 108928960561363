.Header-Logo a{
  display: flex;
}

.LogoLetter {
  width: auto;
  transition: transform 300ms ease;
}

.LogoLetter.loading {
  transform: translateX(1em);
}