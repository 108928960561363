.About-Portrait .Image {
  max-width: 200px;
  width: 100%;
  height: auto;
  @include media-breakpoint-down(lg) {
    max-width: 150px;
  }
}



.About-CV {
  max-height: 13em;
  overflow: hidden;
}

.About-MitwirkendeContainer {
  margin-top: 6rem;
}



.About-LogosContainer {
  margin-top: 8rem;
}

.About-Logos {
  display: flex;
  margin-bottom: -2rem;
}

.About-LogosHeadline {
  margin-bottom: 2rem;
}

.About-Logo {
  margin-bottom: 2rem;
  a {
    display: block;
  }
  .Image {
    max-width: 140px;
    height: auto;
  }
}