.VimeoContainer{

  width: 100%;
  padding-top: 56.25%;
  position: relative;

  iframe{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 200;
  }

  .VimeoLoader{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-wrap: nowrap;
  }

  .VimeoLoader-Element{
    width: 8px;
    height: 8px;
    margin: 4px;
    background-color: $black;
    transform: translateX(0) rotate(0deg);
    transition: transform 200ms ease;
  }
  .VimeoLoader-Element.active{
    transform: translateX(8px) rotate(90deg);
  }

}