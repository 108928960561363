.Footer{
  padding: .5rem;
  color: $black;
  font-size: 11px;
  position: relative;
  z-index: 999;
  height: 3rem;
  user-select: none;
  display: flex;
  align-items: flex-end;
}
.LngLink-Container{
  position: fixed;
  bottom:.5rem;
  right: .5rem;
  display: flex;
}
.LngLink{
  height: 2rem;
  width: 2rem;
  display: block;
  background: $white;
  color: $black;
  border-radius: 100%;
  line-height: 2rem;
  text-align: center;
  margin-left: .5rem;
  box-sizing: content-box;
  border: 1px solid  $black;
  &:hover{
    color: $black;
  }
  &.active{
    border: 1px solid  $black;
    background-color: $black;
    color:  $white;
  }
}