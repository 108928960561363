.HomeView {
  position: relative;
  width: 100%;
  /*padding-top: 50vh;*/
}

.HomeTitleDisplay {
  position: fixed;
  top: 33.333vh;
  left: 0;
  right: 0;
  height: auto;
  display: flex;
  align-items: center;
  z-index: 10;
  font-size: 3rem;
}

.HomeTitle {
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 100;
  padding-left: 1rem;
  padding-right: 1rem;
}


.HomeVideo{
  width: 100%;
  height: 100vh;
  padding: 4rem;
  display: flex;
  object-fit: cover;
  margin-bottom: 25vh;
}

.HomeVideo--Inner{
    width: 100%;
    height: 100%;
    position: relative;
 }

.HomeVideo--MuteToggleBtnWrapper{
  border: none;
  background: transparent;
  position: absolute;
  left:0;
  right: 0;
  top:0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: end;
  button{
    overflow: hidden;
    color: $black;
    padding: 0;
    display: block;
    padding: 2rem;
    border: none;
    background: transparent;
    &:hover{
      svg {
        background: $white;

      }
    }
  }
  svg{
    /*background: $white;*/
    border: 1px solid $black;
    border-radius: 9999px;
    display: block;
    width: 2.5rem;
    height: 2.5rem;
  }
}

.HomeImage{
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}


.HomeTranslate {
  transition: transform 600ms ease;
}
.HomeTranslate--before{
  transform: translateY(75%);
}
.HomeTranslate--after{
  transform: translateY(-75%);
}
@include media-breakpoint-down(md){
  .HomeTranslate--before{
    transform: translateY(3rem);
  }
  .HomeTranslate--after{
    transform: translateY(-3rem);
  }
}


.HomeImage a{
  cursor: initial;
}
.HomeImage .link-wrapper{
  width: 100%;
  height: 100%;
}




.HomeImage .Image{
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  @include clickable-cursor;
}

.HomeImage.HomeImage-NoLink .Image{
  cursor: auto;
}






.HomeBottom {
  height: 100vh;
  margin-top: -3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HomeNav{
  width: 75%;
  display: flex;
  @include media-breakpoint-down(md){
    flex-direction: column;
  }
}


.HomeNav-Item{
  text-align: center;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: transparent;
  transition: background-color 100ms linear;
}

.HomeNav-Item:hover{
  background-color: $black;
  color: $white;
}

@include media-breakpoint-up(lg) {
  .HomeNav-Item {
    border-right: 3px solid $black;
    padding-top: .1rem;
    padding-bottom: .1rem;
  }
  .HomeNav-Item.first{
    border-left: 3px solid $black;
  }
}


.HomeNav-Item:hover{
}




