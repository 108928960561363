.ProjectPreview{
  display: block;
}

.ProjectPreview .area-body{
  padding: 3rem;
  padding-bottom: 1rem;
}

.ProjectPreview-Bild{
  margin-bottom: 2rem;
  img{
    width: 100%;
    height: auto;
  }
}

.ProductionPreview-Icon{
  padding-left: 2px;
}