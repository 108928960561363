.icon-container{
  display: block;
  width: 2rem;
  height: 2rem;
  border: none;
  user-select: none;
}

.icon-button, .icon-button:active, .icon-button:focus{
  display: block;
  background: none;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  position: relative;
  @include clickable-cursor;
}

.icon-button.dark{
  background: $black;
  color: $white;
}

.icon-button.light{
  background: $white;
  color: $black;
  border: 1px solid $black;
}

.icon-button svg{
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  fill: $white;
  *{
    stroke: currentColor !important;
    vector-effect: non-scaling-stroke;
  }
}