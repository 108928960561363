a, a:hover {
  text-decoration: none;
  @include clickable-cursor;
}

.text-lg {
  font-size: 1.5rem;
}

.text-2x {
  font-size: 2em;
}

.PageHeadline {
  margin-bottom: 4rem;
}

h1,.h1{
  font-size: 4rem;
  line-height: 100%;
  @include media-breakpoint-down(lg){
    font-size: 2.5rem;
  }
}

h2,.h2{
  font-size: 3rem;
  line-height: 100%;
}

.meta-small{
  font-family: $font-family-monospace;
  font-weight: normal;
  //font-size: .8rem;
  font-size: 11px;
  line-height: 1rem;
}

.meta-large{
  font-family: $font-family-monospace;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
}

.inverted{
  background:  $black;
  color: $white;
}

.inverted-spaced{
  @include inverted-padding;
}


button{
  @include clickable-cursor;
}
.button-reset{
  border: none;
  outline: none;
  background: none;
  padding: 0;
  @include clickable-cursor;
  &:hover, &:focus, &:active{
    border: none;
    outline: none;
    background: none;
  }
}

.size-1{
  font-size: 3rem;
  @include media-breakpoint-down(sm){
    font-size: 2rem;
  }
}
.size-2{
  font-size: 2rem;
  @include media-breakpoint-down(sm){
    font-size: 1.5rem;
  }
}
