.ProductionContent {
  margin-bottom: 4rem;
  padding-top: 3.5rem;
}

.Production-Intro {
  margin-bottom: 2rem;
}

.Production-Intro .Image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  padding: 3rem;
}

@include media-breakpoint-up(md) {
  .Production-Intro {
    height: 720px;
    min-height: 100vh;
    @include make-container();
    @include make-container-max-widths( $max-widths: $container-max-widths, $breakpoints: $grid-breakpoints );
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: -4rem;
    margin-bottom: -2rem;
    padding: 3rem;
  }
  .ProductionInfo {
    margin-top: -2rem;
  }
}


.Production-Title {
  @include media-breakpoint-down(md) {
    margin-top: 2.5rem;
  }
}


.Project-Presskit {
  margin-bottom: 4rem;
  display: flex;

  a {
    display: flex;
    align-items: center;
  }

  .Donwload-Icon svg {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    margin-right: 1rem;
  }
}


.ProductionContent-EventsContainer {
  margin-bottom: 3rem;

  .meta-headline {
    border-left: 1px solid $black;
    border-top: 1px solid $black;
    padding-left: 4px;
    padding-top: 2px;
    margin-bottom: 1.5em;
  }
}

.ProductionContent-EventsControl {
  display: flex;
}

.ProductionContent-Event {
  margin-bottom: 1rem;
}


.ProductionContent-Event.passed .ProductionContent-Event-Head {
  background: #a0a0a0;
  color: $white;
}

.ProductionContent-Event.upcoming .ProductionContent-Event-Head {
  background: $black;
  color: $white;
}


.ProductionContent-Event-Head {
  display: flex;
  justify-content: space-between;
  padding-left: .25em;
  padding-right: .25em;
  padding-top: .1em;
}

.ProductionContent-Event-Content {
  display: flex;
  justify-content: space-between;
  padding-left: .25em;
  padding-right: .25em;
  border-left: 1px solid $black;
}

.Production-Photos {
  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 2.5rem;
    margin-top: 2.5rem;
  }
}
.Production-Photos-Item{
  @include clickable-cursor;
}