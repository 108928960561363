.Project{
  img{
    width: 100%;
    height: auto;
  }
}

.ProjectCover{
  @include media-breakpoint-up(md){
    width: 50%;
  }
}
