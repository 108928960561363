.ArchiveEntry{
  border-left: 5px solid $black;
  border-top: 2px solid $black;
  padding-left: 4px;
  padding-top: 3px;
  margin-top: 1rem;
  width: auto;
  h3{
    width: auto;
    margin-bottom: 0;
  }
}