.AboutTab {
  border-top: 1px solid $black;
}



.AboutTab-Header {
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
}
.AboutTab-Header.isClickable {
  @include clickable-cursor;
  &:hover {
    background-color: $black;
    color: $white;
  }
}

.AboutTab-Header.sup-lvl {
  padding: 1rem;
}

.AboutTab-Icon svg {
  display: block;
  width: 1em;
  height: 1em;
  border: 1px solid $black;
  background-color: $white;
  border-radius: 100%;
  vector-effect: non-scaling-stroke !important;
  stroke-width: 1px;

  * {
    vector-effect: non-scaling-stroke !important;
  }
}


.AboutTab-Header.open.sup-lvl {
  background-color: $black;
  color: $white;
}



.AboutTab-Body{
  padding: 0.25rem 0.5rem;
}

.AboutTab-Body.sup-lvl{
  padding: 2rem;
  @include media-breakpoint-down(sm){
    padding: 2rem 1rem ;

  }
}