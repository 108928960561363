.wrapper{
    min-height: 1.5em;
    padding: .15em .2em;
    line-height: 1.2;
}

.inverted{
    background: #111111;
    color: #ffffff;
}

.disabled{
    background: #a0a0a0;
}
