.ProductionPreview{
  display: block;
}

.ProductionPreview .area-body{
  padding: 2rem;
  @include media-breakpoint-up(sm){
    padding: 4rem;
  }
  padding-bottom: 3rem;
}




.ProductionPreview-Bild{
  margin-bottom: 3rem;
  img{
    display: block;
    width: 100%;
    height: auto;
  }
}


.ProductionPreview-Subheadline{
  margin-bottom: 0;
}

.ProductionPreview-Icon{
  padding-left: 2px;
}