.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}





.home-title-fade-enter {
  opacity: 0;
  transform: translateY(2.5rem);
}
.home-title-fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.home-title-fade-exit {
  opacity: 1;
  transform: translateY(0);
}
.home-title-fade-exit-active {
  opacity: 0;
  transform: translateY(-2.5rem);
  transition: opacity 300ms, transform 300ms;
}