.PageContainer{
  position: relative;
  min-height: 100vh;
  padding-top: 6rem;
  padding-bottom: 4rem;
  margin-bottom: -3rem;
  margin-left: 4rem;
  margin-right: 4rem;
  @include media-breakpoint-down(md){
    margin-left: .5rem;
    margin-right: .5rem;
  }
}

.content-block{
  margin-bottom: 4rem;
}
