/*
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;
*/




.wrapper {
    display: block;
    padding-bottom: 4rem;
    height: 100%;
}

@media (max-width: 991px) {
    .wrapper{
        padding-bottom: 3rem;
    }
}




.inner {
    border-left: 1px solid black;
    height: 100%;
    display: flex;
    flex-direction: column;
}




.figure {
    margin: 4rem;
}

@media (max-width: 991px) {
    .figure{
        margin: 2rem;
    }
}

.figure img {
    width: 100%;
    display: block;
    height: auto;
}




.text {
    margin: 4rem;
}

@media (max-width: 991px) {
    .text{
        margin: 2rem;
    }
}




.footer{
    margin-top: auto;
}