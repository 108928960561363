.NewsEntry{
  margin-bottom: 2rem;
}

.NewsEntry{
  border-left: 1px solid $black;
}

.NewsEntry-Headline{
  padding: 0 1rem;
  margin: 1rem 0;
}

.NewsMatrix-Block.block-text{
  padding: 0 1rem;
  margin: 1rem 0;
}

.NewsMatrix-Block.block-images{
  margin: 1rem 0;
  padding-left: 1rem;
  padding-right: 1rem;
  img{
    display: block;
    width: 100%;
    height: auto;
  }
  .NewsMatrix-Image{
    margin-bottom: 1rem;
  }
}


.NewsEntry-Button{
  display: block;
  width: auto;
  padding-left: 2px;
  svg{
    display: block;
    width: auto;
    transform: rotate(0);
    transition: transform 600ms ease;
  }
}

.NewsEntry.open .NewsEntry-Button svg{
  transform: rotate(45deg);
}