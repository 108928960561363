.List{


}
.List-Header{
  padding: 2px;
  line-height: 1;
  background-color: $black;
  color: $white;
}

.List-Item{
  border-bottom: 1px solid $black;
  padding: 2px;
}