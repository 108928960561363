.Lightbox{

  position: fixed;
  z-index: 5000;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fefefe;

}

.Lightbox-CloseBtn{
  width: 100%;
  height: 3rem;
  position: absolute;
  top: 0;
  left: 0;
  padding: .5rem;
}

.Lightbox-ImageContainer{
  height: 100%;
  overflow-y: auto;
  padding-left: .5rem;
  padding-right: .5rem;
  padding-top: 3rem;
}

.Lightbox .Image{
  width: 100%;
  height: auto;
  margin-bottom: .5rem;
}
